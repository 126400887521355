import React from "react";
import ContentBuilder from "@organisms/ContentBuilder";
import { ShareContainer } from "@molecules";
import { Container, Text, ButtonAlt } from "@atoms";

const ContentBody = ({ type, back, hero, blocks, url }) => {
  return (
    <section className="mt-32 mb-16 md:mt-48 md:mb-24">
      <article className="flex flex-wrap">
        <Container variant="xs">
          <ButtonAlt reverse to={back?.url || "/"}>
            {back?.text || "Return"}
          </ButtonAlt>
          <div className="my-8 flex flex-col space-y-4">
            <Text variant="h2">{hero.heading}</Text>
            <Text variant="lg">{hero.descriptor}</Text>
          </div>
        </Container>
        <div className="mt-8 w-full md:mt-16">
          <ContentBuilder blocks={blocks} />
          <ShareContainer url={url} />
        </div>
      </article>
    </section>
  );
};

ContentBody.defaultProps = {};

export default ContentBody;
